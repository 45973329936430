import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"
import ArrowBandaid from "./../components/icons/ArrowBandaid"
import base64svg from "./../components/icons/base64svg"

import getSlugFromString from "../utils/get-slug-from-string"

const Wrapper = styled.div`
  padding: 1.5rem 1.375rem 3.75rem;
`

const Inner = styled.div`
  max-width: 40rem;
  margin: 0 auto;

  ${props => props.flex && `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `}

  ${props => props.height && `
    height: ${props.height};
  `}

  ${props => props.color && `
    h2 {color: ${props.color};}
  `}
`

const Content = styled.div`
  h2 {
    margin: 0;
    font: 1.6rem/110% ${props => props.theme.headingFont};
    top: 3rem;
    padding: 0.8em 0;
    background: linear-gradient(white 70%, rgba(255,255,255,0) 100%);
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: black;
  }

  h3 + p img {
    margin-top: 1rem;
  }
`

const Footer = styled.footer`
  position: fixed;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.6rem;
  overflow: hidden;

  a:hover {
    background-color: ${props => props.colorCurrent} !important;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
  color: white;
  padding: 0.6em 1em;
  border-radius: 5rem;
  background-color: ${props => props.color};
  transition: all .3s ease-in-out;
  max-width: 50%;

  img {
    width: 1.4em;
    margin: auto 0.6rem;
  }

  &::before,
  &::after {
    display: inline-block;
    vertical-align: top;
    width: 1.4rem;
    height: 100%;
    flex-shrink: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: background-image;
    transition: background-image .3s ease-in-out;
  }

  @media (max-width: 500px) {
    padding: 0.4em 0.5em;
  }

  @media (max-width: 374px) {
    font-size: 0.9rem;

    img {
      margin: auto 0.2rem;
    }

    &::before,
    &::after {
      width: 1rem;
    }
  }

  &.prev {
    &::before {
      content: "";
      background-image: url(${base64svg(<ArrowBandaid dir="left" />)});
      right: calc(100% + 0.25rem);
    }
  }

  &.next {
    margin-left: auto;
    justify-content: flex-end;

    &::after {
      content: "";
      background-image: url(${base64svg(<ArrowBandaid dir="right" />)});
      left: calc(100% + 0.25rem);
    }
  }

  &:hover {
    color: white !important;
    /* filter: drop-shadow(0 0 5px rgba(0,0,0,0.5)); */
    /* filter: saturate(0%) contrast(150%); */
    box-shadow: 0 0 0.5rem rgba(0,0,0,0.5);
  }
`

export default function TeenageInfoTemplate({
  data: { item: { title, text, thumbnailIcon, thumbnailBgColor }, prevItem, nextItem },
  pageContext: { lang },
}) {
  const intl = useIntl()

  return (
    <Wrapper>
      <NwHelmet lang={lang} path={getSlugFromString(title)} title={title} />
      <Header
        lang={lang}
        title={intl.formatMessage({ id: "readAboutAnaesthesiaAndOperation" })}
        room={7}
        roomSymbol={thumbnailIcon}
        color={thumbnailBgColor}
      />
      <Content>
        <Inner color={thumbnailBgColor}>
          <h2>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: get(text, "html") }} />
        </Inner>
      </Content>
      <Footer colorCurrent={thumbnailBgColor}>
        {prevItem &&
          <StyledLink
            className="prev"
            style={{ backgroundColor: prevItem.thumbnailBgColor }}
            to={`/${lang}/${getSlugFromString(prevItem.title)}`}
          >
            {prevItem.title}
            {prevItem.thumbnailIcon && <img src={prevItem.thumbnailIcon} alt="" role="none" />}
          </StyledLink>
        }
        {nextItem &&
          <StyledLink
            className="next"
            style={{ backgroundColor: nextItem.thumbnailBgColor }}
            to={`/${lang}/${getSlugFromString(nextItem.title)}`}
          >
            {nextItem.thumbnailIcon && <img src={nextItem.thumbnailIcon} alt="" role="none" />}
            {nextItem.title}
          </StyledLink>
        }
      </Footer>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $order: Float, $prevItem: Float, $nextItem: Float) {
    item: teenageInfoYaml(lang: { eq: $lang }, order: { eq: $order }) {
      title
      text {
        html
      }
      thumbnailBgColor
      thumbnailIcon
    }
    prevItem: teenageInfoYaml(lang: { eq: $lang }, order: { eq: $prevItem }) {
      title
      thumbnailBgColor
      thumbnailIcon
    }
    nextItem: teenageInfoYaml(lang: { eq: $lang }, order: { eq: $nextItem }) {
      title
      thumbnailBgColor
      thumbnailIcon
    }
  }
`
